
import { computed, defineComponent, onMounted, reactive, ref, watch, onBeforeMount, nextTick } from 'vue';
// import Swiper JS
import Swiper from 'swiper';
import { getCompanyEventList, getCompanyTeamList } from '@/api/about';
import { getRichText } from '@/api/webIndex';

interface TeamRoot {
	[key: string]: string;
}


export default defineComponent({
	name: 'CompanyIntroduce',
	components: {},
	setup() {
		const bgUrl = require('@/assets/company_build_bg.png');
		const identifyUrl = require('@/assets/identify_list.png');
		const companyBgUrl = ref('');
		let swiper = reactive({}) as Swiper,
			// 当前选中的年份
			currentYear = ref(0),
			linePosObj = reactive({ start: 0, end: 0 }),
			eventList: any[] = reactive([]),
			teamList: any[] = reactive([]);
		const distance = 90;
		// 浏览器宽度
		const sloganList = [
			{ title: '开拓进取', desc: '孕育希望，承载梦想，鼓励创新' },
			{ title: '真诚信任', desc: '尊重理解，有效沟通，以实待人' },
			{ title: '高效共赢', desc: '应对挑战，团队合作，高效执行' }
		];
		const defaultTeamList = [
			/*{
				img_url: bgUrl, team_name: '研发团队', slogan: '创新就是活力', desc:
					['总人数占比超过公司的50%，骨干成员主要来自华为、中兴、烽火科技等国内大中型企业。近年来一大批985、211高校优秀毕业生加入，为研发创新注入新的血液和活力。',
						'“在科技的光与影里，我们是一群认真、务实、乐观的人，激情与创新交融，用程序代码‘智造’未来教育场景。”']
			},
			{
				img_url: bgUrl, team_name: '研发团队', slogan: '创新就是活力', desc:
					['总人数占比超过公司的50%，骨干成员主要来自华为、中兴、烽火科技等国内大中型企业。近年来一大批985、211高校优秀毕业生加入，为研发创新注入新的血液和活力。',
						'“在科技的光与影里，我们是一群认真、务实、乐观的人，激情与创新交融，用程序代码‘智造’未来教育场景。”']
			},
			{
				img_url: bgUrl, team_name: '研发团队', slogan: '创新就是活力', desc:
					['总人数占比超过公司的50%，骨干成员主要来自华为、中兴、烽火科技等国内大中型企业。近年来一大批985、211高校优秀毕业生加入，为研发创新注入新的血液和活力。',
						'“在科技的光与影里，我们是一群认真、务实、乐观的人，激情与创新交融，用程序代码‘智造’未来教育场景。”']
			},
			{
				img_url: bgUrl, team_name: '研发团队', slogan: '创新就是活力', desc:
					['总人数占比超过公司的50%，骨干成员主要来自华为、中兴、烽火科技等国内大中型企业。近年来一大批985、211高校优秀毕业生加入，为研发创新注入新的血液和活力。',
						'“在科技的光与影里，我们是一群认真、务实、乐观的人，激情与创新交融，用程序代码‘智造’未来教育场景。”']
			}*/
		];

		onBeforeMount(async () => {
			// 获取公司简介背景
			try {
				const { error_code, data } = await getRichText();
				if (error_code === 0) {
					companyBgUrl.value = data.company_profile_photo ? data.company_profile_photo : bgUrl;
				} else {
					companyBgUrl.value = bgUrl;
				}
			} catch (e) {
				companyBgUrl.value = bgUrl;
			}
			// 获取大事记列表
			try {
				const { error_code, data } = await getCompanyEventList();
				if (error_code === 0) {
					const temp = data.map((item: any) => {
						return { ...item, year: parseInt(item.year) };
					});
					eventList.push(...temp);
				} else {
					eventList.push(...[]);
				}
			} catch (e) {
				eventList.push(...[]);
			}

			// 获取团队列表
			try {
				const { error_code, data } = await getCompanyTeamList();
				if (error_code === 0) {
					teamList.push(...data);
				} else {
					teamList.push(...[]);
				}
			} catch (e) {
				teamList.push(...[]);
			}

			// 处理大事记逻辑
			currentYear.value = eventList[eventList.length - 1]?.year;

			await nextTick(() => {
				console.log('onMounted');
				setLinePosObj();
				swiper = new Swiper('.swiper', {
					direction: 'horizontal',
					allowTouchMove: false,
					on: {
						setTransition: (swiper, transition) => {
							console.log(swiper, transition);
						}
					}
				});

				swiper.slideTo(activeYearIndex.value, 0, true);
			});
		});

		// 激活的年份的位置
		const activeYearPosition = computed(() => {
			return 600;
		});
		// 激活年份的index
		const activeYearIndex = computed(() => {
			let active = 0;
			eventList.forEach((item, index) => {
				if (item.year === currentYear.value) {
					active = index;
				}
			});
			return active;
		});
		const activeYearObj = computed(() => {
			return eventList.find(item => item.year === currentYear.value) || {};
		});
		// 处理各年份的position
		const eventListWithPosition = computed(() => {
			let list: any[] = [];
			eventList.forEach((item, index) => {
				let pos = 0;
				if (index < activeYearIndex.value) {
					pos = activeYearPosition.value - (activeYearIndex.value - index) * distance;
				} else if (index > activeYearIndex.value) {
					pos = activeYearPosition.value + (index - activeYearIndex.value) * distance;
				} else {
					pos = activeYearPosition.value;
				}
				list.push({ ...item, pos });
			});
			return list;
		});


		const getLinePosObj = () => {
			let start = 0, end = 0;
			start = eventListWithPosition.value.length ? eventListWithPosition.value[0].pos : start;
			end = eventListWithPosition.value.length ?
				eventListWithPosition.value[eventListWithPosition.value.length - 1].pos : end;
			return { start, end };
		};

		const setLinePosObj = () => {
			const { start, end } = getLinePosObj();
			linePosObj.start = start;
			linePosObj.end = end;
		};

		watch(currentYear, () => {
			setLinePosObj();
		});

		const handleClickYearItem = (item: any) => {

			currentYear.value = item.year;
			console.log('handleClickYearItem', activeYearIndex.value, currentYear.value);
			swiper.slideTo(activeYearIndex.value, 1000, true);
		};

		onMounted(() => {

		});
		return {
			companyBgUrl, identifyUrl,sloganList, teamList, eventList, currentYear, eventListWithPosition,
			linePosObj, activeYearObj, activeYearIndex,
			handleClickYearItem
		};
	}
});
