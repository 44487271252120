import axios from '@/utils/axios';
import { BASE_URL } from '@/utils/common';
import Qs from 'qs';

export function getCompanyEventList(data) {
	return axios({
		url: `${BASE_URL}memorabilia/list${Qs.stringify({ ...data })}`,
		method: 'get'
	});
}

export function getCompanyTeamList(data) {
	return axios({
		url: `${BASE_URL}team/list${Qs.stringify({ ...data })}`,
		method: 'get'
	});
}

export function getAreaList(data) {
	return axios({
		url: `${BASE_URL}area/list${Qs.stringify({ ...data })}`,
		method: 'get'
	});
}
