
import { defineComponent, onBeforeMount, reactive } from 'vue';
import { getAreaList } from '@/api/about';
import configList from '@/hooks/config';

export default defineComponent({
	name: 'ContactUs',
	components: {},
	setup() {
		const bgUrl = require('@/assets/contact_us_bg.jpg');
		let dataList: any[] = reactive([]);
		onBeforeMount(async () => {
			// 团队列表
			try {
				const { error_code, data } = await getAreaList();
				if (error_code === 0) {
					dataList.push(...data);
				} else {
					dataList.push(...[]);
				}
			} catch (e) {
				dataList.push(...[]);
			}
		});

		const { phoneOf400, phoneOf027, address } = configList();
		return { bgUrl, dataList, phoneOf400, phoneOf027, address };
	}
});
