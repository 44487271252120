
import { computed, defineComponent, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import CompanyIntroduce from '@/views/about/components/CompanyIntroduce.vue';
import ContactUs from '@/views/about/components/ContactUs.vue';
import { COMPANY_CATEGORY_DESC, COMPANY_CATEGORY_CONTACT } from '@/utils/company';

interface CategoryRoot {
	[key: string]: string;
}

const componentCategoryPair = {
	[COMPANY_CATEGORY_DESC]: 'CompanyIntroduce',
	[COMPANY_CATEGORY_CONTACT]: 'ContactUs'
} as CategoryRoot;

export default defineComponent({
	name: 'About',
	components: {
		CompanyIntroduce,
		ContactUs
	},
	setup() {
		const route = useRoute();
		const currentComponent = ref('');

		const setCurrentComponent = () => {
			console.log(route.params)
			currentComponent.value = componentCategoryPair[route.params?.key as string || COMPANY_CATEGORY_DESC];
		};

		watch(route, () => {
			setCurrentComponent();
		});

		// 默认显示“智慧教室终端”
		setCurrentComponent();

		return {
			currentComponent
		};
	}
});
